const firebaseConfig = {
    apiKey: "AIzaSyBxk4S9RncEB1vzH8ptJstgVbx0coH5UMA",
    authDomain: "dashi-demo.firebaseapp.com",
    projectId: "dashi-demo",
    storageBucket: "dashi-demo.appspot.com",
    messagingSenderId: "1073286924517",
    appId: "1:1073286924517:web:1f3a69633af6442d67ec0c"
};

export default firebaseConfig;
