import App from './App';
import './index.scss';
import { bootstrap } from '../../core';
import firebaseConfig from './firebaseConfig';

import config from './config';
import AppStore from './stores/AppStore';
import PersistStore from './stores/PersistStore';
import UIStore from './stores/UIStore';
import ColorizerStore from './stores/ColorizerStore';

bootstrap(firebaseConfig, config, {
    persist: new PersistStore({}),
    app: new AppStore(),
    colorizer: new ColorizerStore(),
    ui: new UIStore(),
});

export default App;
